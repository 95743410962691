document.addEventListener("DOMContentLoaded", function (event) {
    document.querySelectorAll('tr[data-href]').forEach(row => {
        row.addEventListener("click", function () {
            document.location = this.dataset.href;
        });
    });
});

let currentSlide = 0;
let divSlide = document.getElementById("documents-container");
if (divSlide) {
    divSlide.style.overflow = "visible";
}

function slide(direction) {
    let baseWidth = window.innerWidth < 420 ? 100 : 25;
    let minus = window.innerWidth < 420 ? 0 : 3;
    if (direction === "left" && currentSlide === 0) return;
    currentSlide = direction === "left" ? currentSlide + 1 : currentSlide - 1;
    console.log(currentSlide, document.getElementsByClassName("document-cell").length);
    if (direction === "right" && currentSlide === -(document.getElementsByClassName("document-cell").length - minus)) currentSlide = 0;
    if (divSlide) {
        divSlide.style.transform = "translateX(" + currentSlide * baseWidth + "%)";
    }
}

window.slide = slide;

// Lien de partage
document.querySelectorAll('[data-share]').forEach(element => {
    var location = encodeURI(window.location.href);
    //remove last / in location
    var lengthUrl = location.length;
    var testSL = location.substring(lengthUrl - 1, lengthUrl);
    if (testSL === '/') {
        var locationFinal = location.substring(0, lengthUrl - 1);
    } else {
        var locationFinal = location;
    }
    var title = encodeURIComponent(element.getAttribute('title'));
    var url = false;
    switch (element.getAttribute('data-share')) {
        case "facebook":
            url = "http://www.facebook.com/sharer/sharer.php?u=" + locationFinal + "&title=" + title;
            break;
        case "googleplus":
            url = "https://plus.google.com/share?url=" + locationFinal;
            break;
        case "twitter":
            url = "http://twitter.com/intent/tweet?url=" + locationFinal;
            break;
        case "pinterest":
            var $_meta = document.querySelector('meta[property="og:image"]');
            url = "http://pinterest.com/pin/create/bookmarklet/?" +
                ($_meta ? "media=" + encodeURI($_meta.getAttribute("content")) + "&" : "") +
                "url=" + locationFinal + "&is_video=false&description=" + title;
            break;
        case "linkedin":
            url = "https://www.linkedin.com/shareArticle?url=" + locationFinal + "&title=" + title + "&mini=true&source=invest";
            break;
    }
    if (url) {
        element.setAttribute("href", url);
        element.setAttribute("target", "_blank");
    }
});

function generateICS(eventDate, eventEndDate, eventName, eventDescription) {
    const startDate = new Date(eventDate);
    let endDate;

    if (eventEndDate) {
        endDate = new Date(eventEndDate);
    } else {
        endDate = new Date(startDate);
        endDate.setHours(endDate.getHours() + 1);
    }

    const formatStartDate = startDate.toISOString().replace(/-|:|\.\d+/g, '');
    const formatEndDate = endDate.toISOString().replace(/-|:|\.\d+/g, '');

    const icsData = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${eventName}
DESCRIPTION:${eventDescription}
DTSTART:${formatStartDate}
DTEND:${formatEndDate}
END:VEVENT
END:VCALENDAR`;

    const blob = new Blob([icsData], {type: 'text/calendar;charset=utf-8'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = eventName.replace(/ /g, "_");
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}
window.generateICS = generateICS;

document.addEventListener("DOMContentLoaded", function () {
    var elementToRemove = document.querySelector("p.event-featured__content");
    if (elementToRemove) {
        let nextElement = elementToRemove.nextElementSibling;
        elementToRemove.remove();

        // Ajouter la classe aux balises <p> entre elementToRemove et la div suivante
        while (nextElement && !nextElement.classList.contains("event-featured__btns")) {
            if (nextElement.tagName === "P") {
                nextElement.classList.add("event-featured__content");
            }
            nextElement = nextElement.nextElementSibling;
        }
    }
    var buttonsStartingWith = getButtonsStartingWith('financial_result_');

    buttonsStartingWith.forEach(function (button) {
        button.addEventListener('click', function () {
            setActiveButton(this);
            handleClick(this);
        });
    });
    //Mobile click
    var selectFinancialResultMobile = document.getElementById('mobile_financial_result_select');
    if (selectFinancialResultMobile) {
        if (selectFinancialResultMobile.options.length > 0) {
            var firstOption = selectFinancialResultMobile.options[0];
            firstOption.selected = true;
            var event = new Event('change');
            selectFinancialResultMobile.dispatchEvent(event);
        }

        selectFinancialResultMobile.addEventListener('change', function () {
            var selectedOption = this.options[this.selectedIndex];
            handleClick(selectedOption);
        });
    }
    var currentUrl = window.location.pathname;
    if (currentUrl.includes('/finance/') || currentUrl.endsWith('/finance')) {

        var divsPageHeader = document.querySelectorAll('.page-header');
        for (var i = 0; i < divsPageHeader.length - 1; i++) {
            if (divsPageHeader[i].querySelector('.sub-menu')) {
                if (divsPageHeader[i + 1]) {
                    divsPageHeader[i + 1].classList.remove('page-header');
                }
                break;
            }
        }

        var links = document.querySelectorAll('.sub-menu-list .sub-menu-link');
        var longestDataUrlLink = null;

        links.forEach(function(link) {
            var dataUrl = link.getAttribute('data-url');
            if (dataUrl && currentUrl.startsWith(dataUrl)) {
                if (!longestDataUrlLink || dataUrl.length > longestDataUrlLink.getAttribute('data-url').length) {
                    if (longestDataUrlLink) {
                        longestDataUrlLink.classList.remove('is-current');
                    }
                    longestDataUrlLink = link;
                }
            }
        });

        if (longestDataUrlLink) {
            longestDataUrlLink.classList.add('is-current');
        }
    }

    var tagsPublicationsDiv = document.getElementById('tags_publications');
    if (tagsPublicationsDiv) {
        tagsPublicationsDiv.addEventListener('click', function (event) {
            event.preventDefault();
        });
    }

    document.querySelectorAll('.icd').forEach(function(element) {
        element.addEventListener('click', function(event) {
            let url = atob(element.getAttribute('data-icd'));
            window.location.href = url;

            event.preventDefault();
        });
    });
});

function handleClick(clickedButton) {
    let url = window.location.pathname;
    let parent = clickedButton.getAttribute('data-parent');
    let annee = clickedButton.getAttribute('data-year');
    let urlsParts = url.split('/');
    let lang = (urlsParts.length > 0 && urlsParts[1] == 'en') ? "/en" : "";

    let resultContainer = document.getElementById("ajax_result_chiffre_cle");
    let currentYear = resultContainer.getAttribute('data-year');
    let moveDirection;

    if (parseInt(annee) > parseInt(currentYear)) {
        moveDirection = "100%";
    } else if (parseInt(annee) < parseInt(currentYear)) {
        moveDirection = "-100%";
    } else {
        moveDirection = "0%";
    }

    resultContainer.style.transition = "transform 0.3s ease-out";
    resultContainer.style.transform = `translateX(${moveDirection})`;

    setTimeout(() => {
        fetch(lang + "/resultats-financiers/" + parent + "/" + annee)
            .then(response => response.text())
            .then(data => {
                resultContainer.innerHTML = data;
                resultContainer.setAttribute('data-year', annee);
                resultContainer.style.transition = "none";
                resultContainer.style.transform = "translateX(0)";

                setTimeout(() => {
                    resultContainer.style.transition = "transform 0.3s ease-out";
                }, 50);
            });
    }, 300);
}

function getButtonsStartingWith(prefix) {
    return document.querySelectorAll('[id^="' + prefix + '"]');
}

function setActiveButton(clickedButton) {
    var buttonsWithPrefix = getButtonsStartingWith('financial_result_');
    buttonsWithPrefix.forEach(function (button) {
        button.classList.remove('tag-list-button');
        button.classList.remove('tag-list-button-is-active');
        button.classList.add('tag-list-button');
    });

    clickedButton.classList.add('tag-list-button-is-active');
}
//ce code de popin n'est applicaple que si on a un bloc push simplifié

var divBlockPush = document.getElementById('push-modal-section');
if(divBlockPush){
    //prevent scroll on body when modal is open
    document.body.style.overflow = 'hidden';
    //allow scroll on body when modal is closed
    var pushModal = document.querySelector('.push-modal-btn');
    var pushModalBackground = document.querySelector('.push-modal-background');
    if(pushModal){
        pushModal.addEventListener('click', function () {
            document.body.style.overflow = 'auto';
            document.body.style.overflowX = 'hidden';
        });
    }
    if(pushModalBackground){
        pushModalBackground.addEventListener('click', function () {
            document.body.style.overflow = 'auto';
            document.body.style.overflowX = 'hidden';
        });
    }
}

function handlePopinClic(elementId) {
    var originalAnchor = document.getElementById(elementId);
    var href = originalAnchor.getAttribute('href');
    if (href) {
        var existingPopinAnchors = document.querySelectorAll('a[data-info="popin"]');
        existingPopinAnchors.forEach(function (anchor) {
            anchor.parentNode.removeChild(anchor);
        });
        var newAnchor = document.createElement('a');
        newAnchor.href = href;
        newAnchor.setAttribute('data-info', 'popin');
        document.body.appendChild(newAnchor);
        newAnchor.click();
    }
}

// @todo move this code to app.js and include in full/liste_documents/document_icade.html.twig
document.addEventListener("DOMContentLoaded", function () {
    const filterWrapper = document.querySelector('.js-document-filter');

    if (filterWrapper) {
        const buttons = filterWrapper.querySelectorAll('.js-tag-list-button[data-href]');

        buttons.forEach((btn) => {
            btn.addEventListener('click', () => {
                document.location.href = btn.dataset.href;
            });
        });
    }
});

document.addEventListener('DOMContentLoaded', function() {
    let tagButtons = document.querySelectorAll('.tag-list-button[data-tag][data-field]');
    tagButtons.forEach(button => {
        button.addEventListener('click', function () {
                const context = this.getAttribute('data-context');
                if (!context || context.trim() !== 'map') {
                    const tag = this.getAttribute('data-tag');
                    const field = this.getAttribute('data-field');
                    const singleClick = this.getAttribute('data-signle-clic');
                    const href = window.location.href;
                    const urlParams = new URLSearchParams(window.location.search);
                    let fieldValues = urlParams.getAll(field + '[]');
                    if (singleClick && singleClick.trim() !== '') {
                        urlParams.set(field, tag);
                    } else if (fieldValues.includes(tag)) {
                        urlParams.delete(field + '[]');
                        fieldValues = fieldValues.filter(value => value !== tag);
                        fieldValues.forEach(value => urlParams.append(field + '[]', value));
                    } else {
                        urlParams.append(field + '[]', tag);
                    }
                    urlParams.set('clicked_field', field);
                    const newUrl = href.split('?')[0] + '?' + urlParams.toString();
                    window.location.href = newUrl;
                }
            }
        );
    });
});

document.addEventListener("DOMContentLoaded", function() {
    let pastEventDiv = document.getElementById("past_event");
    if ((pastEventDiv !== null) && window.location.href.includes("clicked_field")) {
        pastEventDiv.scrollIntoView(true);
    }
});

function calculateReadingTime(article) {
    const content = article.innerText || article.textContent;
    const words = content.split(/\s+/).length;
    const readingSpeed = 200;

    return Math.ceil(words / readingSpeed);
}

function displayReadingTime() {
    const article = document.querySelector('#content-actu');
    const readingTime = calculateReadingTime(article);

    const readingTimeElement = document.createElement('div');
    readingTimeElement.classList.add('reading-time');
    readingTimeElement.textContent = `Temps de lecture: ${readingTime} min`;

    article.insertBefore(readingTimeElement, article.firstChild);
}

displayReadingTime();
